import React from 'react';
import { PageHeader } from '@ver-uds/react';

const MaintenanceBanner = (): JSX.Element => {
  return (
    <PageHeader variant="error">
      myE-Verify is currently down for planned maintenance. Please try again later.
    </PageHeader>
  );
};

export default MaintenanceBanner;
